<template>
    <div class="collect-container">
        <div class="page-title">我的收藏</div>
        <div class="table-container">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                @select-all="handleSelectionChange">
                <el-table-column type="selection" width="80">
                    <template slot-scope="scope">
                        <el-checkbox v-if="scope.row.goods && scope.row.goods.status === 1" :data-index="scope.$index"
                            v-model="scope.row.checked" @change="checkboxChange"></el-checkbox>
                        <span v-else>此商品已失效</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品名称">
                    <template slot-scope="scope">
                        <div class="goods-detail">
                            <div class="goods-cover">
                                <img :src="scope.row.goods && scope.row.goods.cover" alt="">
                            </div>
                            <div @click="jumpDetail(scope.row)" class="goods-name">{{ scope.row.goods && scope.row.goods.name }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="产品型号">
                    product_model
                    <template slot-scope="scope">{{ scope.row.product_model }}</template>
                </el-table-column>
                <el-table-column prop="address" label="操作" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div @click="deleteHandle(scope.row.collection_id)" class="delete"><i class="el-icon-delete"></i>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="delete-container">
                <span @click="deleteHandle(collection_ids)">
                    <i class="el-icon-delete"></i>
                    <span>批量删除</span>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [],
            collection_ids: [],
            listParams: {
                page: 1,
                per_page: 10
            },
            total: 0
        }
    },
    created() {
        this.getData()
    },
    methods: {
        checkboxChange(v) {
            let { target } = event
            let index = target.parentNode.parentNode.dataset.index
            let item = this.tableData[index]
            this.$refs.multipleTable.toggleRowSelection(item, v)
            console.log(v, index, item);
            //q 判断v是否为true 如果为true 那么复选框的状态为选中
            if (v) {
                item.checked = true
                // 选中后将id添加到数组中
                this.collection_ids.push(item.collection_id)
                // 如果都选中了 那么全选按钮也应该选中

            } else {
                item.checked = false
                // 取消选中后将id从数组中删除
                this.collection_ids.splice(this.collection_ids.indexOf(item.collection_id), 1)
            }
        },
        jumpDetail(item) {
            if (item.goods.status == 0) return ''
            this.$router.push({ path: '/goods/detail', query: { id: item.goods.id, production_model: item.product_model } })
        },
        deleteHandle(params) {
            console.log(params)
            if (params.length == 0) {
                this.$message({
                    message: '请选择要删除的商品',
                    type: 'error'
                })
                return false
            }
            this.$confirm('此操作将删除改商品, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log(typeof params, params)
                if (typeof params == 'number') {
                    this.delete(params)
                } else {
                    this.deleteMultiple(params)
                }
            }).catch(() => { })
        },
        // 批量删除
        async deleteMultiple(params) {
            console.log(111111, params)
            await this.$http.delete('/api/my-collection/batch-destroy', { collection_ids: params })
            this.getData()
        },
        // 删除
        async delete(collection_id) {
            await this.$http.delete(`/api/my-collection/${collection_id}`)
            this.getData()
        },
        async getData() {
            const res = await this.$http.get('/api/my-collection', this.listParams)
            res.forEach(item => {
                item.checked = false
            })
            this.tableData = res
        },
        handleSelectionChange(val) {
            console.log(val);
						if (this.collection_ids && this.collection_ids.length > 0) {
							this.collection_ids = []
							this.$refs.multipleTable.clearSelection()
							this.tableData.forEach(item => {
								item.checked = false;
							});
						} else {
							let ids = [];
							// let allValid = true; // 是否所有商品均有效
							val.forEach(item => {
								if (item.goods && item.goods.status === 1) {
									ids.push(item.collection_id);
								} else {
									// this.$refs.multipleTable.toggleRowSelection(item, false);
									// allValid = false;
								}
							});
							// if (allValid) {
							// 	// 所有商品均有效，将所有商品的 ID 添加到数组中
							// 	this.tableData.forEach(item => {
							// 		if (item.goods && item.goods.status === 1) {
							// 			ids.push(item.collection_id);
							// 		}
							// 	});
							// }
							this.collection_ids = ids;
							this.tableData.forEach(item => {
								item.checked = ids.indexOf(item.collection_id) > -1;
							});
						}
        },
    }
}
</script>
<style lang="scss" scoped>
.collect-container {
    padding: 5px 0;

    .page-title {
        font-weight: bold;
        line-height: 1;
        padding-left: 10px;
        border-left: 3px solid #D9262C;
        font-size: 18px;
    }

    .table-container {
        margin-top: 25px;

        .delete-container {
            margin-top: 20px;

            >span {
                display: inline-flex;
                align-items: center;
                color: #D9262C;
                cursor: pointer;
            }

            .el-icon-delete {
                font-size: 16px;
                margin-right: 5px;
            }
        }

        .goods-detail {
            display: flex;
            align-items: center;

            .goods-cover {
                width: 40px;
                height: 40px;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .goods-name {
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .delete {
            font-size: 18px;
            cursor: pointer;
        }
    }
}
</style>
